.header {
  position: fixed;
  z-index: 99;

  color: var(--color-W);

  width: 100%;
  top: 0px;
}


.headerContainer {
  display: flex;
  background-color: var(--color-P1);
  margin: 0 auto;
  padding: 5px 20px 10px;

  justify-content: space-between;
  align-items: center;
  max-width: calc(var(--display-pc) - 40px);

}

.logo {
  display: flex;
  flex-direction: column;
}

.logo a {
  font-size: 2.5rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--color-Gr1);

  transition: color 0.3s;
}

.logo a:last-child {
  font-size: 0.8rem;
  color: var(--color-W);
  font-weight: 500;

}

.logo a:visited {
  /* color: inherit; */
}

.logo a:hover {
  color: var(--color-Gr-hover);
}


.logo a:last-child:hover {
  color: var(--color-W);
}



.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  /* 중앙 정렬 */
}

.nav-item {
  margin-left: 20px;
  display: flex;
  align-items: center;
  /* 내부 요소들 수직 중앙 정렬 */
}

.nav-link {
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 9999px;
}

.nav-link:hover {

  background-color: var(--color-P5);
  transition: 0.3s ease-out;

}


.nav-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 4px;
  align-items: flex-end;
}

.logout-button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0px 16px 5px;
  font-size: 0.75rem;
  /* margin-left: 10px; */
}

.logout-button:hover {
  color: var(--color-P5);
  font-weight: 600;
  transition: 0.2s;
  background-color: transparent;
}



.nav-menu {
  display: flex;
  gap: 12px;
}

.nav-link.Active {
  font-weight: 500;
  background-color: var(--color-P5);
  transition: 0.2s;
}


.nav-link.Active:hover {
  background-color: var(--color-P5);
  transition: 0.2s;
}


@media screen and (max-width: 1400px) {

  .headerContainer {
    display: flex;
    background-color: var(--color-P1);
    margin: 0 auto;
    padding: 10px 20px;

    justify-content: space-between;
    align-items: center;
    max-width: calc(var(--display-tablet) - 40px);

  }

}


@media screen and (max-width: 1024px) {

  .headerContainer {
    display: flex;
    background-color: var(--color-P1);
    margin: 0 auto;
    padding: 10px 20px;

    justify-content: space-between;
    align-items: center;
    max-width: calc(100% - 40px);

  }

}
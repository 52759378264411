.VerificationBox {
  width: calc(100% - (var(--marginpadding20) * 2));
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.VerificationBox p {
  font-size: 0.8rem;
  margin: 0;
}

.VerificationBox .input-group {
  display: flex;
  gap: 10px;
}

.VerificationBox .input-group button {
  flex: 1 60px;
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Noto Sans KR", "Noto Sans", sans-serif, serif;
}

h1,
h2,
h3,
h4,
h5,
.input-group label {
  color: #333333;

}

#root {
  overflow-x: hidden;
}

body {
  margin: 0;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

html {
  background-color: #333333;
}

/* focus outline제거 */

input:focus,
select:focus {
  outline: none;
  border: none;
}

button {
  border: none;
}

:root {
  /* 컬러시스템 */
  --color-P1: #436dbb;
  --color-P2: #295cbb;

  --color-P3: #8eade7;
  --color-P4: #718fac;
  --color-P5: #092f77;
  --color-P6: #3c4555;

  --color-header-pointcolor: #819DD1;

  --color-red: #DB3434;
  --color-blue: #118aa0;

  --color-Gr1: #40ec5a;
  --color-Gr-hover: #00ce45;


  --color-G1: #333333;
  --color-G2: #424242;
  --color-G3: #646464;

  --color-G4: #7f7f7f;
  --color-G5: #d1d1d1;
  --color-G6: #f1f2fd;
  --color-G7: #fafafa;

  --color-P7: #FAFCFF;

  --color-W: #fff;
  --color-W2: #f9f9f9;

  --color-blocked: #f0f0f0;

  /* 병원명 추가 */

  --color-DENT: #57B1A1;
  --color-GS: #EFA133;
  --color-IM: #8351EB;
  --color-NP: #DF76A7;

  --color-DENT-selected: #278675;
  --color-GS-selected: #cf7901;
  --color-IM-selected: #5922c9;
  --color-NP-selected: #b6546c;

  /* 헤더 영역 컬러 시스템 */

  --header-main-color: var(--color-P1);
  --header-border-color: var(--color-P2);

  /* 버튼 영역 컬러 시스템 */

  /* 메인버튼 (Header, CTA)*/
  --button-Mdefault-color: var(--color-P1);
  --button-Mhover-color: var(--color-P5);
  --button-Mactive-color: var(--color-P5);
  /*  */
  --button-Sdefault-color: var(--color-P2);
  --button-Shover-color: var(--color-P6);
  --button-Sactive-color: var(--color-P6);

  /* 버튼 */
  --button-Ldefault-color: var(--color-P4);
  --button-Lhover-color: var(--color-P4);
  --button-Lactive-color: var(--color-P4);

  /* 그림자 */

  --boxshadow: inset 1px 1px 3px 1px #718fac40;
  --focus-border-line: solid 1px var(--color-P3);
  /* Break Ponit pc:1920 기준 mobile: 1024 기준 */

  --display-pc: 1200px;
  --display-tablet: 1024px;
  --display-mobile: 800px;



  /* margin/padding 시스템 */
  --marginpadding90: 100px;
  --marginpadding20: 20px;
  /* 켄도 */
  --kendo-color-app-surface: var(--color-W);
  /* 글씨 색상 */
  --kendo-color-on-app-surface: var(--color-G1) !important;
  --kendo-color-subtle: #666;
  --kendo-color-surface: #fafafa;
  --kendo-color-surface-alt: var(--color-W);
  --kendo-color-border: rgba(0, 0, 0, 0.08);
  --kendo-color-border-alt: rgba(0, 0, 0, 0.16);
  --kendo-color-base-subtle: #ebebeb;
  --kendo-color-base-subtle-hover: #e0e0e0;
  --kendo-color-base-subtle-active: #d6d6d6;
  --kendo-color-base: #f5f5f5;
  --kendo-color-base-hover: #ebebeb;
  --kendo-color-base-active: #d6d6d6;
  --kendo-color-base-emphasis: #c2c2c2;
  --kendo-color-base-on-subtle: var(--color-G1);
  --kendo-color-on-base: var(--color-G1);
  /* 버튼 아이콘 or 텍스트의 색상 */
  --kendo-color-base-on-surface: var(--color-G1);
  --kendo-color-primary-subtle: #ffeceb;
  --kendo-color-primary-subtle-hover: #ffdedb;
  --kendo-color-primary-subtle-active: #ffc8c4;
  --kendo-color-primary: var(--color-P1) !important;
  --kendo-color-primary-hover: var(--color-P2) !important;
  --kendo-color-primary-active: var(--color-P5) !important;
  --kendo-color-primary-emphasis: #ff9d97;
  --kendo-color-primary-on-subtle: #5c201c;
  --kendo-color-on-primary: var(--color-W);
  --kendo-color-primary-on-surface: var(--color-P1) !important;
  --kendo-color-secondary-subtle: #fafafa;
  --kendo-color-secondary-subtle-hover: #f5f5f5;
  --kendo-color-secondary-subtle-active: #ebebeb;
  --kendo-color-secondary: #666;
  --kendo-color-secondary-hover: #525252;
  --kendo-color-secondary-active: var(--color-G1);
  --kendo-color-secondary-emphasis: #e0e0e0;
  --kendo-color-secondary-on-subtle: #141414;
  --kendo-color-on-secondary: var(--color-W);
  --kendo-color-secondary-on-surface: #292929;
  --kendo-color-tertiary-subtle: #d8f1fd;
  --kendo-color-tertiary-subtle-hover: #c5eafc;
  --kendo-color-tertiary-subtle-active: #a3dffb;
  --kendo-color-tertiary: #03a9f4;
  --kendo-color-tertiary-hover: #039ae0;
  --kendo-color-tertiary-active: #028ccb;
  --kendo-color-tertiary-emphasis: #61c9f9;
  --kendo-color-tertiary-on-subtle: #023f5c;
  --kendo-color-on-tertiary: var(--color-W);
  --kendo-color-tertiary-on-surface: #028ccb;
  --kendo-color-info-subtle: #d2e2fb;
  --kendo-color-info-subtle-hover: #bdd4f8;
  --kendo-color-info-subtle-active: #80acf4;
  --kendo-color-info: #0058e9;
  --kendo-color-info-hover: #0052d6;
  --kendo-color-info-active: #004ac2;
  --kendo-color-info-emphasis: #6098f2;
  --kendo-color-info-on-subtle: #002259;
  --kendo-color-on-info: var(--color-W);
  --kendo-color-info-on-surface: #004ac2;
  --kendo-color-success-subtle: #dcf0d3;
  --kendo-color-success-subtle-hover: #cbe9bf;
  --kendo-color-success-subtle-active: #b7e1a5;
  --kendo-color-success: #37b400;
  --kendo-color-success-hover: #32a500;
  --kendo-color-success-active: #2d9600;
  --kendo-color-success-emphasis: #81d15f;
  --kendo-color-success-on-subtle: #1c5a00;
  --kendo-color-on-success: var(--color-W);
  --kendo-color-success-on-surface: #2d9600;
  --kendo-color-warning-subtle: #fff4d3;
  --kendo-color-warning-subtle-hover: #ffeebd;
  --kendo-color-warning-subtle-active: #ffe79e;
  --kendo-color-warning: #ffc000;
  --kendo-color-warning-hover: #eaaf00;
  --kendo-color-warning-active: #d49f00;
  --kendo-color-warning-emphasis: #ffd760;
  --kendo-color-warning-on-subtle: #5e4700;
  --kendo-color-on-warning: var(--color-G1);
  --kendo-color-warning-on-surface: #ffc000;
  --kendo-color-error-subtle: #fcddda;
  --kendo-color-error-subtle-hover: #fbc8c3;
  --kendo-color-error-subtle-active: #f98b80;
  --kendo-color-error: #f31700;
  --kendo-color-error-hover: #df1600;
  --kendo-color-error-active: #ca1400;
  --kendo-color-error-emphasis: #f76f60;
  --kendo-color-error-on-subtle: #7a0c00;
  --kendo-color-on-error: var(--color-W);
  --kendo-color-error-on-surface: #ca1400;
  --kendo-color-light-subtle: #fafafa;
  --kendo-color-light-subtle-hover: #f5f5f5;
  --kendo-color-light-subtle-active: #ebebeb;
  --kendo-color-light: #ebebeb;
  --kendo-color-light-hover: #e0e0e0;
  --kendo-color-light-active: #d6d6d6;
  --kendo-color-light-emphasis: #d6d6d6;
  --kendo-color-light-on-subtle: #141414;
  --kendo-color-on-light: #000;
  --kendo-color-light-on-surface: #e0e0e0;
  --kendo-color-dark-subtle: #c2c2c2;
  --kendo-color-dark-subtle-hover: #adadad;
  --kendo-color-dark-subtle-active: #999;
  --kendo-color-dark: var(--color-G1);
  --kendo-color-dark-hover: #292929;
  --kendo-color-dark-active: #1f1f1f;
  --kendo-color-dark-emphasis: #666;
  --kendo-color-dark-on-subtle: #1f1f1f;
  --kendo-color-on-dark: var(--color-W);
  --kendo-color-dark-on-surface: #141414;
  --kendo-color-inverse-subtle: #c2c2c2;
  --kendo-color-inverse-subtle-hover: #adadad;
  --kendo-color-inverse-subtle-active: #999;
  --kendo-color-inverse: var(--color-G1);
  --kendo-color-inverse-hover: #292929;
  --kendo-color-inverse-active: #1f1f1f;
  --kendo-color-inverse-emphasis: #666;
  --kendo-color-inverse-on-subtle: #1f1f1f;
  --kendo-color-on-inverse: var(--color-W);
  --kendo-color-inverse-on-surface: #141414;
  --kendo-color-series-a: var(--color-P1) !important;
  --kendo-color-series-a-bold: #bf4a42;
  --kendo-color-series-a-bolder: #80322c;
  --kendo-color-series-a-subtle: #ffb1ac;
  --kendo-color-series-a-subtler: #ff8a82;
  --kendo-color-series-b: #ffe162;
  --kendo-color-series-b-bold: #bfa94a;
  --kendo-color-series-b-bolder: #807131;
  --kendo-color-series-b-subtle: #fff0b1;
  --kendo-color-series-b-subtler: #ffe989;
  --kendo-color-series-c: #4cd180;
  --kendo-color-series-c-bold: #399d60;
  --kendo-color-series-c-bolder: #266940;
  --kendo-color-series-c-subtle: #a6e8c0;
  --kendo-color-series-c-subtler: #79dda0;
  --kendo-color-series-d: #4b5ffa;
  --kendo-color-series-d-bold: #3847bc;
  --kendo-color-series-d-bolder: #26307d;
  --kendo-color-series-d-subtle: #a5affd;
  --kendo-color-series-d-subtler: #7887fb;
  --kendo-color-series-e: #ac58ff;
  --kendo-color-series-e-bold: #8142bf;
  --kendo-color-series-e-bolder: #562c80;
  --kendo-color-series-e-subtle: #d6acff;
  --kendo-color-series-e-subtler: #c182ff;
  --kendo-color-series-f: #ff5892;
  --kendo-color-series-f-bold: #bf426e;
  --kendo-color-series-f-bolder: #802c49;
  --kendo-color-series-f-subtle: #ffacc9;
  --kendo-color-series-f-subtler: #ff82ae;

}


h2 {
  margin-bottom: 40px;
}


/* 라벨과 함께씀 개인정보 동의에 사용 */
h4 {
  font-weight: 600;
}

.MainBtncolor {
  background-color: var(--color-P1);
  color: var(--color-W);
  border-radius: 4px;
  min-height: 40px;
  cursor: pointer;
}

.MainBtncolor:hover {
  background-color: var(--color-P2);
  transition: 0.2s;
}

.MainBtncolor:active {
  background-color: var(--color-P5);
  transition: 0.2s;
}

.MainBtncolor:disabled {
  background-color: var(--color-G5);
  transition: 0.2s;
}


.Subbtncolor {
  background-color: var(--color-P3);
  color: var(--color-W);
  border-radius: 4px;

}

.Subbtncolor:hover {
  background-color: var(--color-P4);
  transition: 0.2s;
}

.Subbtncolor:active {
  background-color: var(--color-P6);
  transition: 0.2s;
}

.DarkBtncolor {
  background-color: var(--color-G4);
  color: var(--color-W);
}

.DarkBtncolor:hover {
  background-color: var(--color-G3);
  color: var(--color-W);

}

.DarkBtncolor:active {
  background-color: var(--color-G2);
  color: var(--color-W);

}

/* 추가해야함.... */
.k-grid {
  border-radius: 8px;
  overflow: hidden;
}

.layoutContainer {
  width: var(--display-pc);
  min-height: 100vh;
  margin: 0 auto;
  padding: 91px 20px 60px;
  background-color: var(--color-W2);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.k-table-thead,
.k-table-header,
.k-table-group-sticky-header,
.k-grid-header,
.k-grouping-header,
.k-grid-add-row,
.k-grid-footer {
  background-color: var(--color-header-pointcolor) !important;

}

.k-column-title {
  color: var(--color-G7);
}

.k-grid .k-table-row.k-table-alt-row {
  background-color:
    color-mix(in srgb, var(--color-P1) 5%, transparent) !important;

}

@media screen and (max-width: 1400px) {
  .layoutContainer {
    width: var(--display-tablet);
    min-height: 100vh;
    margin: 0 auto;
    padding: var(--marginpadding90) var(--marginpadding20) var(--marginpadding20);
    background-color: var(--color-W2);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }

}


@media screen and (max-width: 1024px) {
  .layoutContainer {
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    padding: var(--marginpadding90) var(--marginpadding20) var(--marginpadding20);
    background-color: var(--color-W2);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

  }

}
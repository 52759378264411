/* .login-container {
  max-width: var(--display-pc);
  margin: 0px auto;
  min-height: 100vh;
  padding: 91px 20px 20px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.login-box {
  width: 480px;
  padding-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--color-P);
}

/* 인풋 디자인 변경 10.30 회원가입과 충돌할수도 있음*/

.input-group {
  width: 100%;
  margin-bottom: 15px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-G5);
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
  transition: 0.3s;
}

.input-group input:hover {
  outline: none;
  box-shadow: var(--boxshadow);
  transition: 0.3s
}


.input-group input:focus {
  outline: var(--focus-border-line);
  box-shadow: var(--boxshadow);
  /* border: ; */
  transition: 0.3s;
}

input[type="checkbox"]:focus {
  outline: none;
}

.input-error {
  border-color: #e74c3c;
  background-color: #fce4e4;
}

.checkbox-group {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.checkbox-group label {
  /* margin-left: 8px; */
  color: var(--color-G2);
}

.error-message {
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
  margin-bottom: 15px;
}



.login-button {
  width: 100%;
  padding: 10px;
  height: 44px;

  background-color: var(--button-Mdefault-color);
  color: var(--color-W);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

}

.login-button:hover {
  background-color: var(--button-Mhover-color);

}

.loginMore {
  width: 100%;
  display: flex;
  gap: 8px;

}

.login-container .moreBtn {
  display: flex;
  width: 100%;
  height: 44px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  border-radius: 4px;
  transition: 0.3s;

  font-weight: 400;
}



.login-box>a:hover {
  background-color: var(--color-G2);
  color: var(--color-W);
}
/* 켄도 스타일 이외의 클래스 명은 여기에 추가 */
.calendar-container {
  margin: 0 auto;
  width: var(--display-pc);
  min-height: 100vh;
  display: flex;
  background-color: var(--color-W2);
  /* padding: 91px 0px 0; */
  align-items: normal;
  justify-content: unset;
}

.calendar {
  width: 100%;
  height: auto;

  padding: 20px 0px 20px;
  display: flex;
}

.today {
  background-color: color-mix(in srgb, var(--color-P2) 15%, transparent);
  color: var(--color-P2);
}

.k-scheduler {
  display: flex;
  height: auto !important;
}

.k-scheduler-layout {
  display: flex;
  flex-direction: column;
  height: auto !important;
}


/* 요일 높이 설정  */
.k-scheduler-head .k-scheduler-group {
  /* max-height: 40px; */
}

.k-scheduler-head {
  flex: none !important;
}


.k-scheduler-body {
  flex: 100% 1;
}

.k-input-inner {
  resize: none;

}

.k-dialog {
  border-radius: 4px;
  overflow: hidden;
}

textarea.k-input-inner {
  min-height: 160px;
}

.sunday {
  color: var(--color-red);
}

.saturday {
  color: var(--color-blue)
}




/* department에 따른 색상 변경  및 글자 넘침 방지*/

.departmentTag {
  height: auto;
  display: flex;
  align-items: center;
}

.departmentTag>div {
  width: 100%;
  height: calc(100% - .5rem);
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 .75em 0 0;
}

.k-event-template {
  width: 100%;
  height: calc(100%);

  display: -webkit-box;
  overflow: hidden;
  /* -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
  text-overflow: none !important;

  word-wrap: break-word;
  white-space: wrap;

  font-size: 0.8rem;

  font-weight: 500;
}

.치과 {
  background-color: var(--color-DENT);
}

.치과.k-selected,
.치과:hover {
  background-color: var(--color-DENT-selected);

}

.안과 {
  background-color: var(--color-NP);
}

.안과.k-selected,
.안과:hover {
  background-color: var(--color-NP-selected);
}

.외과 {
  background-color: var(--color-GS);
}

.외과.k-selected,
.외과:hover {
  background-color: var(--color-GS-selected);
}

.내과 {
  background-color: var(--color-IM);
}

.내과.k-selected,
.내과:hover {
  background-color: var(--color-IM-selected);
}



/* 반응형 제작 */
@media screen and (max-width: 1400px) {
  .calendar-container {
      /* padding: 91px 0px 0; */
      margin: 0 auto;
      width: var(--display-tablet);
      min-height: 100vh;

      align-items: normal;
      justify-content: unset;
  }

  .calendar {
      width: 100%;
      height: auto;

      padding: 20px 0px;
      display: flex;
  }

}

@media screen and (max-width: 1024px) {

  .calendar-container {

      width: 100%;
      min-height: 100vh;
  }

  .calendar {
      width: 100%;
      height: auto;

      padding: 20px 0px;
      display: flex;
  }
}
.main {
  max-width: var(--display-pc);
  margin: 0px auto;
  min-height: 100vh;
  padding: var(--marginpadding90) var(--marginpadding20) var(--marginpadding20);

  border: 1px solid #ccc;
  background-color: var(--color-W2);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.btn_container {
  display: flex;
  margin-bottom: 200px;
  gap: 20px;
}

.main button {
  display: flex;
  height: 300px;
  width: 300px;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: 20px;
  font-size: 1rem;
  font-weight: 500;

  border: none;

  border-radius: 8px;

  cursor: pointer;
}

.button_styleP {
  color: var(--color-P1);
  background-color: var(--color-W);
  transition: 0.4s;
  /* box-shadow: inset -5px -5px 9.6px #00000020, inset 5px -5px 10px #8eade730; */
  box-shadow: 1px -1px 8px 1px #8eade730;

}


.button_styleP:hover {
  background-color: var(--color-P7);
  box-shadow: 1px -1px 12px 1px #00000030;
  transition: 0.4s;

}



.button_styleS {
  color: var(--color-W);
  background-color: var(--color-P1);
  transition: 0.4s;

  box-shadow: 1px -1px 10px 2px #00000030;
}


.button_styleS:hover {

  background-color: var(--color-P2);

  box-shadow: 1px 1px 12px 2px #00000060;
}

.button_styleS:active {

  background-color: var(--color-P5);

  box-shadow: 1px 1px 12px 2px #00000060;
}


/* 반응형 제작 */
@media screen and (max-width: 1400px) {

  .main {
    max-width: var(--display-tablet);
    margin: 0px auto;
    min-height: 100vh;
    padding: var(--marginpadding90) var(--marginpadding20) var(--marginpadding20);
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

}


@media screen and (max-width: 1024px) {

  .main {
    max-width: 100%;
    margin: 0px auto;
    min-height: 100vh;
    padding: var(--marginpadding90) var(--marginpadding20) var(--marginpadding20);
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

}



@media screen and (max-width: 600px) {

  .btn_container {
    flex-direction: column;

  }

}
.forgot-password-container,
.reset-password-page {
    display: flex;
    flex-direction: column;
    gap: 24px;

}

.forgot-password-wrapper,
.reset-password-wrapper {
    width: 100%;
    max-width: 480px;
    margin: 0 0 200px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.forgot-password-wrapper h2,
.reset-password-wrapper h2 {
    margin-bottom: 12px;
    /* text-align: left; */
}

.forgot-password-wrapper .text,
.reset-password-wrapper .text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.forgot-password-wrapper p,
.reset-password-wrapper p {
    font-size: 0.9rem;
    text-align: Left;
    margin: 0;
}

.reset-password-button {
    margin-bottom: 16px;
    width: 100%;
    padding: 10px 20px;
}

.reset-password-wrapper .input-group {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
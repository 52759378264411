.PatientListContainer {
  flex-direction: column !important;
  max-height: 100vh;
  justify-content: flex-start;
}


.k-grid {
  width: 100% !important;
  flex: 1 1;
}

.k-table {
  width: 100% !important;
}

.k-pager {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.k-pager-numbers-wrap {
  order: 2;
}

.k-pager-info {
  flex: none !important;
}

.PatientListContainer h2 {
  width: 100%;
  text-align: left;
}


.k-table-td {
  /* 태블릿의 경우 높이 설정 : 컨테이너의 탑/바텀 + 타이틀 크기 + 지정된 마진 * 38(처음 마지막 탑 바텀 제외) + 하부 크기 */
  height: calc((100vh - (var(--marginpadding90) + var(--marginpadding20) + 92px + (var(--kendo-spacing-2, .5rem)) * 38 + var(--kendo-spacing-2, .5rem) + var(--kendo-spacing-2, .5rem) + 48px)) / 21);
}






/* 환자 상세 페이지 */

.PatientDetailPage {

  flex-direction: column;
  justify-content: flex-start;
}

.PatientDetailPage h2 {
    width: 100%;
    text-align: left;
}


.PatientDetail {

  width: 100%;
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.PatientInfoGrid {
  display: flex;
  flex-direction: column;
}

.PatientHealthGrid {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}


.healthViewer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.healthViewer.open {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 24px;
}

.health {
  display: flex;
  margin: 0px 0 0 0;
  height: auto !important;
  flex: 1 1;
  min-width: 300px;
}

.pdf-modal {
  display: flex;
  flex-direction: column;

}

.pdf-modal-content,
.record-detail-modal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.k-text-layer {
  width: 100% !important;
}

.pdfContainer,
.recordContainer {
  width: 100%;
}


.pdfContainer {
  height: 1200px;
}

.openBtn {
  padding: 8px 12px;
  /* margin: 0px 0px 12px 0px; */
  font-size: 13px;
}

.healthViewer.open .openBtn {
  font-size: 10px;
}

.closebutton {
  padding: 8px 12px;
  margin: 0px 0px 12px 0px;
}



/* 운동세부 정보 컴포넌트 css */




@media screen and (max-width: 1400px) {



  .healthViewer.open {
      display: flex;
      width: 100%;
      flex-direction: column;
      gap: 24px;
  }

  .healthViewer.open .openBtn {
      font-size: 13px;
  }
}


@media screen and (max-width: 1024px) {
  .health {
      min-width: 160px;
      margin: 0px 0 0 0;
      height: auto !important;
  }



  .pdf-modal {
      display: flex;
      max-width: 800px;
      flex-direction: column;
      flex: 1 1 100%;
  }

  .healthViewer.open .openBtn {
      font-size: 13px;
  }
}
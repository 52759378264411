.register-container {
  padding: 91px 0px 60px;
}

.register-container form {
  margin: 16px 0px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.register-box {
  width: 100%;
  max-width: 480px;
}

h2 {
  text-align: center;
  /* margin-bottom: 20px; */
}

.register-container .input-group {
  margin-bottom: 0;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}


.input-group .content {
  display: block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 13px;
  word-break: break-all;
  text-align: justify;
  line-height: 160%;
}


.input-group input,
.input-group select {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}



/* 이메일 뒷부분 호버문제 제거 */
.emailAdress:hover {
  box-shadow: none !important;
  cursor: default;
  outline: none !important;
}

.emailAdress:focus {
  box-shadow: none !important;
  cursor: default;
  outline: none !important;
}


.input-group .email-container {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 100%;
}

/* 
.input-group .email-container input:first-child {
  flex: 1;

} */


.input-group .email-container input {
  min-width: 60px;
  flex: 1 1 30%;
}


.input-group .email-container .emailAdress {
  min-width: 60px;
  flex: 1.5 1 30%;
  background-color: var(--color-blocked);
}


.input-group .email-container .verify {
  flex: 1;
}

.privacy {
  padding: 20px 0 0;
  margin: 0 0 20px 0;

  display: flex;
  align-items: center;

  gap: 8px;
}

.privacy span {
  font-weight: 600;
}

.privacy>input {
  width: 16px;
  height: 16px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.register-button {
  width: 100%;
  padding: 10px;
  background-color: var(--color-P1);
  color: var(--color-W);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.6s;

  /* 마지막 회원가입 간격조정 */
  margin: 20px 0 0;


}

.register-button:hover {
  background-color: var(--color-P5);
}

.register-button:active {
  background-color: var(--color-P5);
}

.input-group select {
  width: 100% !important;
  padding: 8px;
  border: 1px solid var(--color-G5) !important;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 14px;
  height: 40px;
}

select:hover {
  outline: none;
  box-shadow: var(--boxshadow) !important;
  transition: 0.3s
}


select:focus {
  outline: var(--focus-border-line);
  box-shadow: var(--boxshadow) !important;
}

option {
  width: 100%;
  outline: none;
  background-color: var(--color-W2);
  color: var(--color-G2);
}



@media screen and (max-width: 1400px) {
  .register-container {

    padding: 91px 20px 60px;

  }

}


@media screen and (max-width: 1024px) {
  .register-container {

    padding: 91px 0px 60px;

  }

}